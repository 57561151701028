import {Box, Typography, useTheme} from '@mui/material';
import {useTranslation} from 'next-i18next';
import {Colors} from '../common/colors';
import {HomeTitle} from '../common/text';
import {Page} from '../common/container';
import {useResponsive} from '../../provider/MediaQueryProvider';
import Hands from '../icons/svg/hands.svg';
import Cart from '../icons/svg/cart.svg';

export default function How() {
    const {isMobile, isTablet} = useResponsive();
    const {t} = useTranslation('common');
    const theme = useTheme();
    const isDark = theme.palette.mode == 'dark';

    const small = isTablet || isMobile;

    const flexDirection = small ? 'column' : 'row';

    return (
        <Page>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignitems: 'center',
                    flex: 1,
                    flexDirection: 'row',
                    paddingTop: '100px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignitems: 'center',
                    }}
                >
                    <HomeTitle>{t('title.how')}</HomeTitle>
                    <Box
                        sx={{
                            marginTop: '50px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: flexDirection,
                                marginBottom: '100px',
                            }}
                        >
                            {small && (
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                        width: '220px',
                                        height: '42px',
                                        margin: 'auto',
                                    }}
                                >
                                    <Cart />
                                </Box>
                            )}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignitems: 'center',
                                    marginRight: small ? '0px' : '30px',
                                    marginTop: small ? '20px' : '0px',
                                    textAlign: small ? 'center' : 'auto',
                                }}
                            >
                                <Typography sx={{marginBottom: '20px'}}>
                                    {t('edito.how_1')}{' '}
                                    <Typography
                                        fontWeight="bold"
                                        color={isDark ? Colors.yellow : Colors.secondDark}
                                        component="span"
                                    >
                                        {t('edito.how_2')}.
                                    </Typography>
                                </Typography>
                                <Typography sx={{marginBottom: '20px'}}>
                                    {t('edito.how_5')}
                                </Typography>
                                <Typography sx={{marginBottom: '10px'}}>
                                    {t('edito.how_6')}
                                    <Typography
                                        fontWeight="bold"
                                        color={isDark ? Colors.yellow : Colors.secondDark}
                                        component="span"
                                    >
                                        {' '}
                                        {t('edito.how_7')}
                                    </Typography>
                                    {t('edito.how_8')}
                                </Typography>
                            </Box>
                            {!small && (
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                        width: '439px',
                                        height: '85px',
                                        margin: 'auto',
                                    }}
                                >
                                    <Cart />
                                </Box>
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: flexDirection,
                            }}
                        >
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    width: '123px',
                                    height: '97px',
                                    margin: 'auto',
                                }}
                            >
                                <Hands />
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignitems: 'center',
                                    marginLeft: small ? '0px' : '30px',
                                    marginTop: small ? '20px' : '0px',
                                    textAlign: small ? 'center' : 'auto',
                                }}
                            >
                                <Typography sx={{marginBottom: '20px'}}>
                                    {t('edito.how_9')}
                                </Typography>
                                <Typography sx={{marginBottom: '10px'}}>
                                    <Typography
                                        fontWeight="bold"
                                        color={isDark ? Colors.yellow : Colors.secondDark}
                                        component="span"
                                    >
                                        {t('edito.how_10')}
                                    </Typography>
                                    {t('edito.how_11')}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Page>
    );
}
