import {Box, Typography, useTheme} from '@mui/material';
import {useTranslation} from 'next-i18next';
import {Colors} from '../common/colors';
import {HomeTitle} from '../common/text';
import {STORAGE} from '../../utils/settings';
import {useResponsive} from '../../provider/MediaQueryProvider';

export default function What() {
    const theme = useTheme();
    const isDark = theme.palette.mode == 'dark';
    const {isMobile, isTablet, isLaptop} = useResponsive();
    const {t} = useTranslation('common');

    const bg = STORAGE + '/bg/' + (isDark ? 'what_dark.svg' : 'what.webp');

    const small = isTablet || isMobile;

    const flexDirection = small ? 'column' : 'row';

    return (
        <Box sx={{marginTop: '-100px', marginBottom: '-100px'}}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignitems: 'center',
                    flex: 1,
                    flexDirection: 'column',
                    backgroundImage: 'url(' + bg + ')',
                    backgroundSize: '1900px',
                    paddingTop: '200px',
                    paddingBottom: '200px',
                    paddingRight: '25px',
                    paddingLeft: '25px',
                }}
            >
                <HomeTitle>{t('title.what')}</HomeTitle>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        flex: 1,
                        flexDirection: flexDirection,
                        marginTop: '100px',
                        alignSelf: small ? 'center' : 'auto',
                        flexWrap: 'wrap',
                    }}
                >
                    <Bloc
                        title={t('edito.what_title_1')}
                        short={t('edito.what_short_1')}
                        description={t('edito.what_1')}
                        star={false}
                    />
                    <Bloc
                        title={t('edito.what_title_2')}
                        short={t('edito.what_short_2')}
                        description={t('edito.what_2')}
                        star={true}
                    />
                    <Bloc
                        title={t('edito.what_title_3')}
                        short={t('edito.what_short_3')}
                        description={t('edito.what_3')}
                        star={false}
                    />
                </Box>
            </Box>
        </Box>
    );
}

type BlocProps = {
    title: string;
    short: string;
    description: string;
    star: boolean;
};

function Bloc(props: BlocProps) {
    const theme = useTheme();
    const isDark = theme.palette.mode == 'dark';
    const {isMobile, isTablet, isLaptop, isWide} = useResponsive();

    const small = isLaptop || isTablet || isMobile;

    const width = small ? '300px' : '400px';

    const src = STORAGE + '/icon/print.svg';

    return (
        <Box
            sx={{
                borderWidth: '3px',
                borderStyle: 'solid',
                borderColor: Colors.secondHover,
                borderRadius: '8px',
                backgroundColor: Colors.secondLight,
                width: width,
                padding: '25px',
                textAlign: 'center',
                marginBottom: small ? '25px' : '0px',
                alignSelf: 'stretch',
            }}
        >
            <Typography
                sx={{
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    marginBottom: '20px',
                    color: Colors.grey,
                }}
            >
                {props.title}
            </Typography>
            <Typography sx={{marginBottom: '10px', color: Colors.grey}}>
                {props.short}
            </Typography>
            <Typography sx={{color: Colors.grey}}>{props.description}</Typography>
            {props.star && (
                <Box sx={{marginTop: '25px'}}>
                    <picture>
                        <source srcSet={src} type="image/png" />
                        <img
                            src={src}
                            width={40}
                            height={40}
                            style={{
                                display: 'block',
                                margin: 'auto',
                            }}
                            referrerPolicy="no-referrer"
                            alt="current"
                        />
                    </picture>
                </Box>
            )}
        </Box>
    );
}
