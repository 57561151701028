import {Box} from '@mui/material';
import {useTranslation} from 'next-i18next';
import {Colors} from '../common/colors';
import {HomeTitle} from '../common/text';
import {STORAGE} from '../../utils/settings';
import {useResponsive} from '../../provider/MediaQueryProvider';
import Vertical from '../icons/svg/future_vertical.svg';
import Horizontal from '../icons/svg/future_horizontal.svg';
import VerticalEn from '../icons/svg/future_vertical_en.svg';
import HorizontalEn from '../icons/svg/future_horizontal_en.svg';
import useWindowSize from '../../hooks/window';

export default function Future() {
    const {isMobile, isTablet} = useResponsive();
    const {t} = useTranslation('common');

    const pattern = STORAGE + '/pattern/' + 'star.webp';

    const vertical = isMobile || isTablet;

    return (
        <>
            <Box sx={{clipPath: 'polygon(0% 0, 100% 5vw, 100% 100%, 0 calc(100% - 5vw))'}}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignitems: 'center',
                        flex: 1,
                        flexDirection: 'column',
                        backgroundColor: Colors.secondBg,
                        backgroundImage: 'url(' + pattern + ')',
                        paddingBottom: vertical ? '50px' : '200px',
                    }}
                >
                    <Box sx={{marginTop: '200px', marginBottom: '100px'}}>
                        <HomeTitle color={Colors.grey}>{t('title.future')}</HomeTitle>
                    </Box>
                    {vertical && <FutureVertical />}
                    {!vertical && <FutureHorizontal />}
                </Box>
            </Box>
        </>
    );
}

function FutureVertical() {
    const {width} = useWindowSize();
    const {i18n} = useTranslation('common');

    const myWidth = width < 420 ? 320 : 400;

    const myHeight = width < 420 ? 850 : 1050;

    const fr = i18n.language == 'fr';

    return (
        <Box width={myWidth} height={myHeight} margin="auto">
            {fr && <Vertical />}
            {!fr && <VerticalEn />}
        </Box>
    );
}

function FutureHorizontal() {
    const {width} = useWindowSize();
    const {i18n} = useTranslation('common');

    const CAP = 1000;

    const size = width > CAP ? CAP : 800;
    const margin = (width - size) / 2;
    const left = margin > 5 ? margin : 5;
    const paddingLeft = `${left}px`;

    const myWidth = width > CAP ? 1600 : 1200;

    const fr = i18n.language == 'fr';

    return (
        <Box sx={{width: '100%', paddingLeft: paddingLeft, overflow: 'hidden'}}>
            <Box marginRight="-20px" width={myWidth}>
                {fr && <Horizontal />}
                {!fr && <HorizontalEn />}
            </Box>
        </Box>
    );
}
