export type Countdown = {
    days: number;
    hours: any;
    minutes: any;
    seconds: any;
    totalSeconds: number;
};

export default function CountdownTimer({countDown}: {countDown: Countdown}) {
    return (
        <>
            {daysText(countDown.days)}
            {elementText(countDown.hours)}
            {elementText(countDown.minutes)}
            {elementText(countDown.seconds, true)}
        </>
    );
}

function daysText(nbDays: number) {
    return nbDays ? `${nbDays} jour` + (nbDays >= 2 ? 's ' : ' ') : '';
}

function elementText(number: number, isLast?: boolean) {
    const placeholder = isLast ? '00' : '   ';
    return number > 0 ? `${zeroPad(number)}${!isLast ? ':' : ''}` : placeholder;
}

function zeroPad(num: number | string) {
    return String(num).padStart(2, '0');
}
