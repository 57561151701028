import {Box, Typography, useTheme} from '@mui/material';

import {Colors} from '../common/colors';
import {HistoryButton} from '../common/button';
import {useTranslation} from 'next-i18next';

// import ForwardRoundedIcon  from '@mui/icons-material/ForwardRounded';

type HistoryProps = {
    withoutActiveCampaign?: boolean;
};

export default function History(props: HistoryProps) {
    if (props.withoutActiveCampaign) {
        return <HistoryWithoutActiveCampaign />;
    } else {
        return <HistoryWithActiveCampaign />;
    }
}

function HistoryWithActiveCampaign() {
    const {t} = useTranslation('common');
    return (
        <Box
            sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: 5,
                paddingRight: 5,
                marginBottom: 10,
                alignSelf: 'center',
                width: '100%',
                maxWidth: {xl: '1280px', md: '640px', sx: '640px'},
            }}
        >
            <Box sx={{alignSelf: {sx: 'center', md: 'flex-end'}}}>
                <HistoryButton href="/history">{t('action.go_to_history')}</HistoryButton>
            </Box>
        </Box>
    );
}

function HistoryWithoutActiveCampaign() {
    const {t} = useTranslation('common');
    const theme = useTheme();
    return (
        <Box
            sx={{
                display: 'flex',
                alignSelf: 'center',
                maxWidth: 500,
                flexDirection: 'column',
                marginLeft: 3,
                marginRight: 3,
                padding: {xs: 4, md: 6},
                marginBottom: 10,
                marginTop: {xs: 4, md: 0},
                backgroundColor: theme.palette.background.default,
                borderRadius: '8px',
                WebkitBoxShadow: Colors.shadow,
                boxShadow: Colors.shadow,
            }}
        >
            <Box sx={{marginBottom: 6}}>
                <Typography sx={{textAlign: 'center', fontSize: 20, fontWeight: 'bold'}}>
                    {t('title.no_campaign')}
                </Typography>
                <Typography
                    sx={{marginTop: 3, fontSize: 16, maxWidth: 400, textAlign: 'center'}}
                >
                    {t('advertisement.no_campaign')}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                }}
            >
                <HistoryButton href="/history">{t('action.go_to_history')}</HistoryButton>
            </Box>
        </Box>
    );
}
