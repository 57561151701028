import {Box, Typography, useTheme} from '@mui/material';

import {Colors} from '../common/colors';
import {FirstHomeTitle} from '../common/text';
import {LogoHome} from '../navigation/logo';
import TitleEn from '../icons/svg/title_en.svg';
import TitleFr from '../icons/svg/title_fr.svg';
import {useResponsive} from '../../provider/MediaQueryProvider';
import {useTranslation} from 'next-i18next';

export default function TitleHome() {
    const theme = useTheme();
    const isDark = theme.palette.mode == 'dark';
    const {isMobile, isTablet, isLaptop} = useResponsive();
    const {t} = useTranslation('common');

    const marginVertical = isMobile
        ? '50px'
        : isTablet
        ? '100px'
        : isLaptop
        ? '100px'
        : '100px';
    const marginHorizontal = isMobile ? '10px' : isTablet ? '20px' : isLaptop ? '20px' : '0px';

    return (
        <Box
            sx={{
                paddingLeft: marginHorizontal,
                paddingRight: marginHorizontal,
                paddingTop: marginVertical,
                paddingBottom: marginVertical,
            }}
            maxWidth="1280px"
            margin="auto"
        >
            <Box
                sx={{
                    textAlign: 'center',
                }}
            >
                <LogoHome />
            </Box>
            {isDark && <FirstHomeTitle>{t('tips.preorder')}</FirstHomeTitle>}
            {!isDark && <SeoSvgTitle />}
            <Typography
                fontSize={isMobile ? '12px' : isTablet ? '14px' : isLaptop ? '18px' : '26px'}
                sx={{
                    color: isDark ? Colors.somber : 'black',
                    fontWeight: 'bold',
                    textAlign: 'center',
                }}
            >
                {t('tips.participate')}
            </Typography>
        </Box>
    );
}

function SeoSvgTitle() {
    const {t, i18n} = useTranslation('common');

    const fr = i18n.language == 'fr';

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignitems: 'center',
                flex: 1,
                flexDirection: 'column',
            }}
        >
            <Typography
                variant="h1"
                component="h1"
                sx={{
                    fontWeight: 'bold',
                    fontSize: '59px',
                }}
            >
                <>
                    {fr && <TitleFr />}
                    {!fr && <TitleEn />}
                </>
            </Typography>
        </Box>
    );
}
