import {Box, Typography, useTheme} from '@mui/material';
import {useTranslation} from 'next-i18next';
import {HomeTitle} from '../common/text';
import {STORAGE} from '../../utils/settings';
import {useResponsive} from '../../provider/MediaQueryProvider';
import Image from 'next/image';

export default function Why() {
    const theme = useTheme();
    const isDark = theme.palette.mode == 'dark';
    const {isMobile, isTablet, isLaptop} = useResponsive();
    const {t} = useTranslation('common');

    const bg = STORAGE + '/bg/' + (isDark ? 'why_dark.webp' : 'why.webp');

    const src = STORAGE + '/why/' + (isDark ? 'why_dark.svg' : 'why.webp');

    const small = isTablet || isMobile;

    const flexDirection = small ? 'column' : 'row';

    const width = small ? 250 : isLaptop ? 250 : 450;
    const height = Math.floor(width / (191 / 212));

    return (
        <Box sx={{marginTop: '-100px', marginBottom: '-100px'}}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignitems: 'center',
                    flex: 1,
                    flexDirection: flexDirection,
                    backgroundImage: 'url(' + bg + ')',
                    paddingTop: '200px',
                    paddingBottom: '200px',
                    paddingRight: '25px',
                    paddingLeft: '25px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignitems: 'center',
                    }}
                >
                    <HomeTitle>{t('title.why')}</HomeTitle>
                    <Box
                        sx={{
                            marginTop: '50px',
                            maxWidth: '600px',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            textAlign: 'justify',
                        }}
                    >
                        <Typography sx={{marginBottom: '10px'}}>{t('edito.why_3')}</Typography>
                        <Typography sx={{marginBottom: '10px'}}>{t('edito.why_4')}</Typography>
                        <Typography sx={{marginBottom: '10px'}}>{t('edito.why_5')}</Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignitems: 'center',
                        marginTop: small ? '50px' : '0px',
                    }}
                >
                    <Box sx={{textAlign: 'center'}}>
                        <Image src={src} alt={'Pourquoi ?'} width={width} height={height} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
