import {useSearchParams} from 'next/navigation';
import {useCountdown} from '../../hooks/countdown';
import {Box, Fade, Modal, Typography} from '@mui/material';
import {STORAGE} from '../../utils/settings';
import CountdownTimer from './countdownTimer';
import {useRef, useState} from 'react';
import Coin from '../icons/svg/coin.svg';

const BLACK_GRADIENT = 'linear-gradient(#1c1c1e77, #14141477)';
const COUNTDOWN_FADE_DURATION = 3000;

export default function RevealCurtain() {
    const searchParams = useSearchParams();
    const countdownTarget = searchParams.get('reveal'); // exemple ?reveal=2024-03-06T18:00:00
    const countDown = useCountdown(countdownTarget);
    const [open, setOpen] = useState(true);
    const [fadeOut, setFadeout] = useState(false);
    const fadingTimeout = useRef<ReturnType<typeof setTimeout>>();

    const startFadeOut = () => {
        if (fadingTimeout.current != null) {
            clearTimeout(fadingTimeout.current);
        }
        if (countDown.totalSeconds <= 0) {
            setFadeout(true);
            fadingTimeout.current = setTimeout(() => {
                setOpen(false);
                fadingTimeout.current = undefined;
            }, COUNTDOWN_FADE_DURATION);
        }
    };

    if (!searchParams.has('reveal')) {
        return null;
    }
    return (
        <Modal open={open} hideBackdrop>
            <Fade in={!fadeOut} timeout={COUNTDOWN_FADE_DURATION} onClick={startFadeOut}>
                <Box
                    sx={{
                        display: 'flex',
                        position: 'fixed',
                        height: '100%',
                        width: '100%',
                        background: 'black',
                        backgroundImage: `url(${STORAGE}/bg/title.svg?v=3), ${BLACK_GRADIENT}`,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 8,
                        }}
                    >
                        {/* <LogoHome forceMode="dark" /> */}
                        <Box sx={{width: 300, height: 300, cursor: 'pointer'}}>
                            <Coin />
                        </Box>
                        <Typography sx={{color: '#e6e6e6', fontSize: 70, fontWeight: 'bold'}}>
                            {countDown.totalSeconds > -2 && (
                                <CountdownTimer countDown={countDown} />
                            )}
                        </Typography>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}
