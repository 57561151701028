import {Box, Typography, useTheme} from '@mui/material';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import {Fragment} from 'react';
import {useTranslation} from 'next-i18next';
import useWindowSize from '../../hooks/window';
import {useResponsive} from '../../provider/MediaQueryProvider';
import {STORAGE, URL_CLIENT} from '../../utils/settings';
import {Colors} from '../common/colors';
import {FlexTitle, HomeTitle} from '../common/text';

export default function FoundersHome() {
    const {t} = useTranslation('common');

    const bg = STORAGE + '/pattern/' + 'piece.webp';

    return (
        <Box sx={{clipPath: 'polygon(0% 5vw, 100% 0%, 100% calc(100% - 5vw), 0 100%)'}}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignitems: 'center',
                    flex: 1,
                    flexDirection: 'column',
                    backgroundImage:
                        'url(' + bg + '), linear-gradient(to right bottom, #ef6d99, #ef9f99)',
                    // backgroundSize: '10%',
                    paddingTop: '200px',
                    paddingBottom: '200px',
                    paddingRight: '5px',
                    paddingLeft: '5px',
                }}
            >
                <HomeTitle color="white">{t('title.founders')}</HomeTitle>
                <Box
                    sx={{
                        marginTop: '100px',
                    }}
                >
                    <FounderList />
                </Box>
            </Box>
        </Box>
    );
}

type FounderListProps = {
    hide?: string | null | undefined;
};

export function FounderList(props: FounderListProps) {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignitems: 'center',
                flex: 1,
                flexDirection: 'row',
                flexWrap: 'wrap',
            }}
        >
            {FOUNDERS.map((f, i) => (
                <Fragment key={f.link}>
                    {f.link != props.hide && <Portrait founder={f} key={f.link} idx={i} />}
                </Fragment>
            ))}
        </Box>
    );
}

const PADDING_PORTRAIT = ['40px', '30px', '20px', '10px', '0px'];

type PortraitProps = {
    founder: FounderDto;
    idx: number;
};

function Portrait(props: PortraitProps) {
    const {isMobile, isTablet, isLaptop} = useResponsive();
    const small = isTablet || isMobile;
    const src = props.founder.image;

    return (
        <Box
            sx={{
                marginTop: '50px',
                marginLeft: '10px',
                marginRight: '10px',
                paddingTop: PADDING_PORTRAIT[props.idx],
            }}
        >
            <Link href={'/founder/' + props.founder.link} aria-label={props.founder.name}>
                <Image
                    src={src}
                    alt={props.founder.name}
                    width={small ? 150 : 200}
                    height={small ? 273 : 364}
                />
            </Link>
        </Box>
    );
}

type FounderDto = {
    name: string;
    description: string;
    image: string;
    url: string;
    link: string;
};

export const BENZAIE = {
    name: 'Benzaie',
    description:
        'Benjamin Daniel dit Benzaie (/ˈbɛnzaɪ/) est un vidéaste et acteur français né le 29 septembre 1987 à Niort (Deux-Sèvres).',
    image: STORAGE + '/founder/benzaie.webp',
    url: 'benzaie',
    link: 'benzaie',
} as FounderDto;
export const BOBLENON = {
    name: 'Bob Lennon',
    description:
        'Bob Lennon est un vidéaste web, auteur et comédien français spécialisé dans le jeu vidéo né le 3 avril 1987 à Romans-sur-Isère, dans la Drôme.',
    image: STORAGE + '/founder/bob-lenon.webp',
    url: 'bob-lennon',
    link: 'bob-lennon',
} as FounderDto;
export const JDG = {
    name: 'Jdg',
    description:
        'Joueur du Grenier Écouter (/ʒwœʁ dy gʁə.nje/) communément abrégé en « JDG », est une émission humoristique de tests de jeux vidéo rétro, diffusée sur YouTube depuis septembre 2009.',
    image: STORAGE + '/founder/jdg.webp',
    url: 'jdg',
    link: 'jdg',
} as FounderDto;
export const SHESHOUNET = {
    name: 'Sheshounet',
    description:
        'Sheshounet est un YouTubeur qui fait des vidéos de lui-même jouer des jeux vidéos variés comme Pokémon, Fortnite, et Red Dead.',
    image: STORAGE + '/founder/sheshounet.webp',
    url: 'sheshounet',
    link: 'sheshounet',
} as FounderDto;
export const ASENKA = {
    name: 'Asenka',
    description:
        'Guillaume Dorison, aussi connu sous les pseudonymes Izu et Asenka, est un auteur né en 1979 et est le frère cadet de Xavier Dorison.',
    image: STORAGE + '/founder/asenka.webp',
    url: 'asenka',
    link: 'asenka',
} as FounderDto;

const FOUNDERS = [BENZAIE, BOBLENON, JDG, SHESHOUNET, ASENKA];

type FounderSeoProps = {
    data: FounderDto;
};

export function FounderSeo(props: FounderSeoProps) {
    const title = `Kunekune | ${props.data.name}`;
    const subtitle = props.data.description;
    const image = props.data.image;

    const urlFr = URL_CLIENT + '/founder/' + props.data.url;
    const urlEn = URL_CLIENT + '/en/founder/' + props.data.url;

    return (
        <Head>
            <title>{title}</title>
            <meta name="description" content={subtitle} />
            <meta property="og:url" content={urlFr} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={subtitle} />
            <meta property="og:image" content={image} />
            <meta property="og:image:alt" content={title} />
            <meta property="og:image:width" content="263" />
            <meta property="og:image:height" content="464" />
            <meta property="og:ttl" content="2419200" />
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:description" content={subtitle} />
            <meta property="twitter:image" content={image} />
            <meta property="twitter:image:alt" content={title} />
            <meta property="twitter:url" content={urlFr} />
            <meta property="twitter:site" content="@KuneKune" />
            <link hrefLang="fr" href={urlFr} rel="canonical" />
            <link hrefLang="en" href={urlEn} rel="alternate" />
        </Head>
    );
}

type OtherFoundersProps = {
    hide?: string | null | undefined;
};

export function OtherFounders(props: OtherFoundersProps) {
    const {t} = useTranslation('founders');
    const theme = useTheme();
    const isDark = theme.palette.mode == 'dark';

    const bg = STORAGE + '/pattern/' + (isDark ? 'piece_grey_dark.png' : 'piece_grey.png');

    return (
        <Box
            sx={{
                backgroundImage: 'url(' + bg + ')',
                backgroundColor: isDark ? Colors.dark : Colors.light,
                paddingTop: '100px',
                paddingBottom: '200px',
                marginBottom: '-150px',
            }}
        >
            <HomeTitle color={isDark ? 'white' : 'black'}>{t('other_founders')}</HomeTitle>
            <Box sx={{marginTop: '50px'}}>
                <FounderList hide={props.hide} />
            </Box>
        </Box>
    );
}

type FounderPageProps = {
    title: string;
    subtitle?: string | null | undefined;
    bio: string;
    hide: string;
    founder: FounderDto;
    bgLargeSource: string;
    bgLargeWidth: number;
    bgLargeHeight: number;
    bgMediumSource: string;
    bgMediumWidth: number;
    bgMediumHeight: number;
    colorFull: string;
    colorEmpty: string;
};

export function FounderPage(props: FounderPageProps) {
    const {width} = useWindowSize();
    const {isMobile, isTablet, isLaptop, isWide} = useResponsive();
    const {t} = useTranslation('common');

    let bg = props.bgLargeSource;
    let IMG_WIDTH = props.bgLargeWidth;
    let IMG_HEIGHT = props.bgLargeHeight;

    if (isTablet || isMobile) {
        /* 1453 × 1064 px */
        bg = props.bgMediumSource;
        IMG_WIDTH = props.bgMediumWidth;
        IMG_HEIGHT = props.bgMediumHeight;
    }

    const src = STORAGE + '/splash/' + bg;

    let fontSizeTitle = '60px';
    let fontSizeSubtitle = '24px';
    let fontSizeText = '16px';

    if (isMobile) {
        fontSizeTitle = '26px';
        fontSizeSubtitle = '16px';
        fontSizeText = '12px';
    } else if (isTablet) {
        fontSizeTitle = '35px';
        fontSizeSubtitle = '22px';
        fontSizeText = '14px';
    } else if (!isWide) {
        fontSizeTitle = '30px';
        fontSizeSubtitle = '18px';
        fontSizeText = '12px';
    }

    const ratioImg = width / IMG_WIDTH;

    const myRatio = Math.floor(IMG_HEIGHT * ratioImg);

    let addTop = 0;
    if (width < 350) {
        addTop = 100;
    } else if (width < 400) {
        addTop = 50;
    } else if (width < 450) {
        addTop = 50;
    } else if (width < 550) {
        addTop = 50;
    } else if (width < 650) {
        addTop = 0;
    } else if (width < 750) {
        addTop = 0;
    } else if (width < 850) {
        addTop = -50;
    }

    const myHeight =
        isMobile || isTablet ? Math.floor(myRatio + addTop + myRatio * 0.5) : myRatio - 5;
    const textHeight = Math.floor(myHeight - myRatio);
    const left = isMobile || isTablet ? 0 : Math.floor(width * 0.05);
    const textPaddingLeft = isMobile || isTablet ? '10%' : `${Math.floor(left)}px`;

    const GRADIENT_HORIZONTAL =
        'linear-gradient(90deg, ' +
        props.colorFull +
        ' 0%, ' +
        props.colorFull +
        ' 70%, ' +
        props.colorEmpty +
        ' 100%)';

    const GRADIENT_VERTICAL =
        'linear-gradient(0deg, ' +
        props.colorFull +
        ' 0%, ' +
        props.colorFull +
        ' ' +
        textHeight +
        'px, ' +
        props.colorEmpty +
        ' ' +
        (textHeight + 50) +
        'px)';

    const gradient = isMobile || isTablet ? GRADIENT_VERTICAL : GRADIENT_HORIZONTAL;

    const justifyContent = isMobile || isTablet ? 'center' : 'flex-start';
    const textAlign = isMobile || isTablet ? 'center' : 'left';
    const textWidth = isMobile || isTablet ? '100%' : '35%';
    const textPaddingRight = isMobile || isTablet ? '10%' : '10%';
    const textPaddingBottom = isMobile || isTablet ? '5%' : '0%';
    const justifyContentText = isMobile || isTablet ? 'flex-end' : 'center';

    return (
        <>
            <FounderSeo data={props.founder} />
            <Box
                sx={{
                    background: 'url(' + src + ')',
                    backgroundColor: props.colorFull,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top right',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        justifyContent: justifyContent,
                        height: myHeight,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                            justifyContent: justifyContentText,
                            alignitems: 'center',
                            maxWidth: textWidth,
                            paddingRight: textPaddingRight,
                            paddingLeft: textPaddingLeft,
                            paddingBottom: textPaddingBottom,
                            background: gradient,
                        }}
                    >
                        <FlexTitle
                            color="white"
                            fontSize={fontSizeTitle}
                            textAlign={textAlign}
                        >
                            {props.title}
                        </FlexTitle>
                        {Boolean(props.subtitle) && (
                            <Typography
                                sx={{
                                    fontSize: fontSizeSubtitle,
                                    marginTop: '10px',
                                    color: 'white',
                                    textAlign: textAlign,
                                }}
                            >
                                {props.subtitle}
                            </Typography>
                        )}
                        <Typography
                            sx={{
                                fontSize: fontSizeText,
                                marginTop: '30px',
                                color: 'white',
                                textAlign: textAlign,
                            }}
                        >
                            {props.bio}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <OtherFounders hide={props.hide} />
        </>
    );
}
