import {Box} from '@mui/material';
import {Colors} from '../common/colors';
import {HomeTitle} from '../common/text';
import Image from 'next/image';
import {STORAGE} from '../../utils/settings';
import {useResponsive} from '../../provider/MediaQueryProvider';
import {useTranslation} from 'next-i18next';

type ComingSoonProps = {};

const CARDS = [
    // {name: 'Maliki', image: 'maliki.webp'},
    // {name: 'Maliki', image: 'maliki_1.webp'},
    // {name: 'Maliki', image: 'maliki_2.webp'},
    // {name: 'Maliki', image: 'maliki_3.webp'},
    {name: 'Benzaie', image: 'benzaie.webp'},
    {name: 'Bob Lennon', image: 'bob_lenon.webp'},
    {name: 'JDG', image: 'jdg.webp'},
    {name: 'Sheshounet', image: 'sheshounet.webp'},
    {name: 'Asenka', image: 'asenka.webp'},
] as ProjectDto[];

export default function ComingSoon(props: ComingSoonProps) {
    const {isMobile, isTablet, isLaptop} = useResponsive();

    const {t} = useTranslation('common');

    const bg = STORAGE + '/pattern/' + 'print.webp';

    let clipPath = 'polygon(0% 0, 100% 5vw, 100% 100%, 0 calc(100% - 5vw))';

    return (
        <Box sx={{clipPath: clipPath}}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignitems: 'center',
                    flex: 1,
                    flexDirection: 'column',
                    backgroundColor: '#ffd14f',
                    backgroundImage: 'url(' + bg + ')',
                    paddingTop: isMobile ? '100px' : '200px',
                    paddingBottom: isMobile ? '100px' : '200px',
                    paddingRight: '5px',
                    paddingLeft: '5px',
                }}
            >
                <HomeTitle color={Colors.grey}>{t('title.coming_soon')}</HomeTitle>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        flex: 1,
                        flexDirection: 'row',
                        marginTop: '100px',
                        flexWrap: 'wrap',
                    }}
                >
                    <ProjectsComingSoon />
                </Box>
            </Box>
        </Box>
    );
}

export function ProjectsComingSoon() {
    return (
        <>
            {CARDS.map((c, i) => (
                <Project project={c} key={i} />
            ))}
        </>
    );
}

type ProjectDto = {
    name: string;
    image: string;
};

type ProjectProps = {
    project: ProjectDto;
};

function Project(props: ProjectProps) {
    const {isMobile, isTablet, isLaptop} = useResponsive();
    const src = STORAGE + '/card/' + props.project.image;

    const small = isTablet || isMobile;

    return (
        <Box
            sx={{
                marginTop: '50px',
                marginLeft: '10px',
                marginRight: '10px',
                transform: 'translatey(0px)',
                animation: 'float 6s ease-in-out infinite',
            }}
        >
            <Image
                src={src}
                alt={props.project.name}
                width={small ? 150 : 200}
                height={small ? 203 : 270}
            />
        </Box>
    );
}
